
import React, {Component} from 'react';
import Provider from '.components/Store/Globals__en';
import BlogPage from 'templates/pages/BlogPage';
import Blocks from './Blocks';



class Layout extends Component {
    constructor(props) {
        super(props);

        
    }

    

    render() {
        return (
            <Provider>
            <BlogPage data={this.props.pageContext.page} Blocks={Blocks} url={this.props.pageContext.url}/>
            </Provider>
        );
    }
}

export default Layout;
